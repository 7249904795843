import * as React from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from '../components/layout/layout'
import Seo from '../components/layout/seo'
import Youtube from '../components/layout/youtube'
import Facebook from '../images/facebook.svg'
import { YTResources, ImageNodes } from '../components/types'

import thumbData from '../images/video/data.json'

// youtube image
// https://img.youtube.com/vi/Xsf4FhpoXjc/maxresdefault.jpg

type IndexProps = {
    allFile: {
        nodes: ImageNodes
    }
}
const IndexPage: React.FC<PageProps<IndexProps>> = ({ data: { allFile: { nodes } } }) => {

    return (
        <Layout>
            <Seo title="Otthon" />
            <h1 className="sr-only">Otthon</h1>
            <div className="flex flex-wrap lg:flex-nowrap justify-between mt-6 -mx-2">
                <div className="w-full lg:w-auto grow flex flex-col mx-2 p-4 bg-white">
                    <Youtube resources={ thumbData.resources as YTResources } nodes={ nodes } />
                </div>
                <div className="w-full lg:w-[38%] mx-2 mt-4 lg:mt-0 p-4 bg-white">
                    <div id="facebook-cookie-warning" className="w-full py-4 text-center">
                        <p className="my-2">A facebook idővonal megtekintéséhez engedélyezd a Közösségi média sütiket!</p>
                        <a className="flex justify-center items-center w-full my-2 p-2 rounded bg-[#0589c9] text-white"
                            target="_blank" rel="noreferrer"
                            href="https://www.facebook.com/noeallatotthon/">
                            <Facebook className="w-6 h-6 mr-4 fill-white" />
                            <span>Noé Állatotthon a Facebookon</span>
                        </a>
                    </div>
                    <div id="facebook" className="fb-page w-full"
                        data-href="https://www.facebook.com/noeallatotthon"
                        data-width="500" data-height="1000"
                        data-adapt-container-width="true"
                        data-tabs="timeline"
                        data-hide-cover="false"
                        data-show-facepile="false"
                        data-small-header="false"
                        data-show-border="false">
                        <blockquote cite="https://www.facebook.com/facebook"
                            className="fb-xfbml-parse-ignore">
                            <a href="https://www.facebook.com/facebook">Meta</a>
                        </blockquote>
                    </div>
                </div>
            </div>
            <div id="fb-root"></div>
        </Layout>
)}

export default IndexPage

export const query = graphql`
    {
        allFile(
            filter: {
                relativeDirectory: { eq: "video" },
                extension: { regex: "/(jpg)|(png)|(jpeg)/" } }
        ) {
            nodes {
                base
                childImageSharp {
                    gatsbyImageData(
                        placeholder: TRACED_SVG
                        layout: FULL_WIDTH
                        breakpoints: [640, 768, 1024, 1280]
                    )
                }
            }
        }
    }
`